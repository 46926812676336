import {IWixStatic} from '@wix/yoshi-flow-editor'
import {EditorSDK} from '@wix/platform-editor-sdk'
import {LIST_LAYOUT, WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {IWixStyleParams} from '@wix/native-components-infra/dist/es/src/types/wix-sdk'
import {WidgetSettingsState} from '../reducers'

interface SetDefaultDimensionsParams {
  Wix: IWixStatic
  editorSDK: EditorSDK
  state: WidgetSettingsState
  layout?: WIDGET_LAYOUT | LIST_LAYOUT
  singleEventWidget: boolean
}

export const setDefaultDimensions = async ({
  Wix,
  state,
  layout,
  singleEventWidget,
  editorSDK,
}: SetDefaultDimensionsParams) => {
  const compId = Wix.Utils.getOrigCompId()
  const [componentRef, styles] = await Promise.all([
    editorSDK.document.components.getById('token', {id: compId}),
    getStyleParams(Wix),
  ])

  const compSettings = {
    ...state.componentConfig.settings,
    ...styles.booleans,
    ...styles.numbers,
  }

  layout = layout ? layout : singleEventWidget ? compSettings.widgetLayout : compSettings.listLayout
  const {width: currentWidth} = await editorSDK.components.layout.get('token', {componentRef})
  const defaultDimensions = getDefaultDimensions(layout, singleEventWidget, compSettings, currentWidth)

  await editorSDK.components.layout.update('token', {
    componentRef,
    layout: defaultDimensions,
  })
}

const getStyleParams = (Wix: IWixStatic): Promise<IWixStyleParams> =>
  new Promise(resolve => {
    Wix.Styles.getStyleParams(resolve)
  })

const MIN_DEFAULT_LIST_LAYOUT_WIDTH = 750

const getDefaultDimensions = (
  layout: WIDGET_LAYOUT | LIST_LAYOUT,
  singleEventWidget: boolean,
  componentConfig: WidgetComponentSettings,
  currentWidth: number,
): {width: number; height?: number} => {
  if (singleEventWidget) {
    switch (layout as WIDGET_LAYOUT) {
      case WIDGET_LAYOUT.BUTTON_ONLY:
        return {
          width: componentConfig.oneButtonWidth + componentConfig.buttonsBorderWidth * 2,
        }
      case WIDGET_LAYOUT.BACKGROUND:
        return {width: 550, height: 550}
      case WIDGET_LAYOUT.VERTICAL:
        return {width: 600, height: 800}
      case WIDGET_LAYOUT.FULL:
      default:
        return {height: 600, width: 860}
    }
  } else {
    switch (layout as LIST_LAYOUT) {
      case LIST_LAYOUT.SIDE_BY_SIDE:
      case LIST_LAYOUT.CALENDAR:
      case LIST_LAYOUT.LIST:
      case LIST_LAYOUT.NEW_GRID:
      default:
        return {width: Math.max(MIN_DEFAULT_LIST_LAYOUT_WIDTH, currentWidth)}
    }
  }
}
