import {isActive, isEnded, EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import {StatusFilter} from '@wix/events-types'
import {createReducer, AnyAction} from '@reduxjs/toolkit'
import {fetchComponentConfig, updateComponentDraft} from '../actions/component-config'
import {setEventFilterType, setCategoryFilter} from '../actions/config'
import {WidgetSettingsState} from '.'

export const componentConfig = createReducer<WidgetComponentConfig>({} as WidgetComponentConfig, builder => {
  builder
    .addCase(fetchComponentConfig.fulfilled, (state, action) => action.payload)
    .addCase(updateComponentDraft.pending, (state, action) => {
      const config = action.meta.arg
      return {
        ...state,
        byEventId: config.byEventId,
        byStatus: config.byStatus,
        byCategoryId: config.byCategoryId,
      }
    })
    .addCase(setEventFilterType.fulfilled, (state, action) => handleSetEventFilterType(state, action))
    .addCase(setCategoryFilter.fulfilled, (state, action) => handleSetCategoryFilter(state, action))
})

const handleSetEventFilterType = (state: WidgetComponentConfig, action: AnyAction): WidgetComponentConfig => {
  if (setEventFilterType.fulfilled.match(action)) {
    const filterType: EVENT_FILTER_TYPE = action.meta.arg.filterType
    const {MANUAL, UPCOMING_AND_PAST, PAST} = EVENT_FILTER_TYPE
    const {FUTURE_AND_PAST, PAST_ONLY, FUTURE_ONLY} = StatusFilter
    const filteredByCategory = Boolean(state.byCategoryId)

    switch (filterType) {
      case MANUAL:
        return {...state, byEventId: {eventId: []}, byStatus: undefined, byCategoryId: undefined}
      case UPCOMING_AND_PAST:
        return {
          ...state,
          byEventId: undefined,
          byStatus: filteredByCategory ? undefined : {...state.byStatus, filter: FUTURE_AND_PAST},
          byCategoryId: filteredByCategory ? {...state.byCategoryId, statusFilter: FUTURE_AND_PAST} : undefined,
        }
      case PAST:
        return {
          ...state,
          byEventId: undefined,
          byStatus: filteredByCategory ? undefined : {...state.byStatus, filter: PAST_ONLY},
          byCategoryId: filteredByCategory ? {...state.byCategoryId, statusFilter: PAST_ONLY} : undefined,
        }
      default:
        return {
          ...state,
          byEventId: undefined,
          byStatus: filteredByCategory ? undefined : {...state.byStatus, filter: FUTURE_ONLY},
          byCategoryId: filteredByCategory ? {...state.byCategoryId, statusFilter: FUTURE_ONLY} : undefined,
        }
    }
  }
}

const handleSetCategoryFilter = (state: WidgetComponentConfig, action: AnyAction): WidgetComponentConfig => {
  if (setCategoryFilter.fulfilled.match(action)) {
    const newCategoryId = action.meta.arg.categoryId

    if (newCategoryId) {
      return {
        ...state,
        byStatus: undefined,
        byCategoryId: {
          categoryId: [newCategoryId],
          statusFilter: state.byCategoryId ? state.byCategoryId.statusFilter : state.byStatus.filter,
        },
      }
    } else {
      return {
        ...state,
        byCategoryId: undefined,
      }
    }
  }
}

export const getComponentConfig = (state: WidgetSettingsState): WidgetComponentConfig => state.componentConfig

export const getComponentSettings = (state: WidgetSettingsState): WidgetComponentSettings =>
  getComponentConfig(state).settings

export const getEventFilterType = (state: WidgetSettingsState): EVENT_FILTER_TYPE => {
  const compConfig = state.componentConfig
  const {FUTURE_AND_PAST, PAST_ONLY} = StatusFilter
  const {MANUAL, UPCOMING_AND_PAST, PAST, UPCOMING} = EVENT_FILTER_TYPE

  if (compConfig.byEventId) {
    return MANUAL
  } else if (
    compConfig.byStatus?.filter === FUTURE_AND_PAST ||
    compConfig.byCategoryId?.statusFilter === FUTURE_AND_PAST
  ) {
    return UPCOMING_AND_PAST
  } else if (compConfig.byStatus?.filter === PAST_ONLY || compConfig.byCategoryId?.statusFilter === PAST_ONLY) {
    return PAST
  } else {
    return UPCOMING
  }
}

export const getCategoryFilter = (state: WidgetSettingsState) => state.componentConfig.byCategoryId?.categoryId?.[0]

export const isEventVisible = (state: WidgetSettingsState, event: wix.events.Event) => {
  const compConfig = state.componentConfig
  const {FUTURE_AND_PAST, PAST_ONLY} = StatusFilter

  if (compConfig.byEventId) {
    return compConfig.byEventId.eventId.includes(event.id)
  } else if (
    compConfig.byStatus?.filter === FUTURE_AND_PAST ||
    compConfig.byCategoryId?.statusFilter === FUTURE_AND_PAST
  ) {
    return true
  } else if (compConfig.byStatus?.filter === PAST_ONLY || compConfig.byCategoryId?.statusFilter === PAST_ONLY) {
    return isEnded(event)
  } else {
    return isActive(event)
  }
}

export const isManualFilterType = (state: WidgetSettingsState) => getEventFilterType(state) === EVENT_FILTER_TYPE.MANUAL

export const manualVisibleEventsCount = (state: WidgetSettingsState) =>
  state.componentConfig.byEventId ? state.componentConfig.byEventId.eventId.length : 0
