import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {IWixAPI} from '@wix/yoshi-flow-editor'
import {Api} from '../utils/api'
import {State} from '../types/state'

type Extra = {
  serverApi: Api
  wixCodeApi: IWixAPI
  compId: string
}

export const createAsyncAction = asyncActionsFactory<State, Extra>()
