import {WidgetData} from '@wix/events-types'
import {EventsHttpClient, LIST_LAYOUT, RECURRING_FILTER, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {addQueryParams, stringifyParams} from '@wix/panda-js-utils'

export interface GetWidgetDataParams {
  compId: string
  language: string
  locale: string
  tz: string
  viewMode: string
  members: boolean
  paidPlans: boolean
  responsive: boolean
  showcase: boolean
  widgetType?: WIDGET_TYPE
  listLayout?: LIST_LAYOUT
  recurringFilter?: RECURRING_FILTER
}

export const getWidgetData =
  (api: EventsHttpClient) =>
  async ({
    responsive = false,
    locale,
    language,
    compId,
    viewMode,
    members,
    paidPlans,
    widgetType,
    listLayout,
    recurringFilter,
    showcase = false,
    tz,
  }: GetWidgetDataParams): Promise<WidgetData> =>
    api.get<WidgetData>(
      addQueryParams(
        `/html/v2/widget-data`,
        stringifyParams({
          compId,
          locale: language,
          regional: locale,
          viewMode,
          members,
          paidPlans,
          responsive,
          widgetType,
          listLayout,
          recurringFilter,
          showcase,
          tz,
        }),
      ),
    )
