import {EventStatus} from '@wix/events-types'
import {AnyAction} from 'redux'
import {LOAD_CALENDAR_EVENTS, LOAD_EVENTS} from '../actions/events'
import {LOAD_MEMBERS_FOR_EVENTS} from '../actions/members'
import {Events} from '../types/state'

const defaultState: Events = {
  events: [],
  moreLoading: false,
  hasMore: false,
}

export const events = (state = defaultState, action: AnyAction): Events => {
  switch (action.type) {
    case LOAD_MEMBERS_FOR_EVENTS.SUCCESS:
      const guestLists = action.payload as GuestLists

      return {
        ...state,
        events: [
          ...state.events.map(event => ({
            ...event,
            ...(guestLists[event.id] || {}),
          })),
        ],
      }
    case LOAD_EVENTS.REQUEST: {
      const {offset} = action.args[0]
      return {
        ...state,
        moreLoading: offset !== 0,
      }
    }
    case LOAD_EVENTS.SUCCESS: {
      const {offset} = action.args[0]
      const reset = offset === 0
      return {
        ...state,
        events: reset ? action.payload.events : [...state.events, ...action.payload.events],
        hasMore: action.payload.hasMore,
        moreLoading: false,
      }
    }
    case LOAD_CALENDAR_EVENTS.SUCCESS: {
      const reset = action.args[2]
      const loadedEvents = reset ? [] : state.events
      const existingEventIds = loadedEvents.map(event => event.id)
      const newEvents = action.payload.events.filter(event => !existingEventIds.includes(event.id))
      return {
        ...state,
        events: [...loadedEvents, ...newEvents],
        hasMore: false,
        moreLoading: false,
      }
    }
    case LOAD_EVENTS.FAILURE:
      return {
        ...state,
        moreLoading: false,
      }
    default:
      return state
  }
}

export const sortEvents = (eventList: wix.events.Event[]) => {
  const upcomingEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.SCHEDULED, EventStatus.STARTED])
  const pastEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.ENDED])
  const canceledEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.CANCELED])

  return [].concat(upcomingEvents, pastEvents, canceledEvents)
}

const getEventsByStatusSortedByDate = (
  eventList: wix.events.Event[],
  statuses: wix.events.EventStatus[],
): wix.events.Event[] => eventList.filter((event: wix.events.Event) => statuses.indexOf(event.status) > -1)
